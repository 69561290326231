<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t("edit") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-if="authUser.isAdmin"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="item.tenant_id"
                  dense
                  disabled
                  :loading="tenantLoading"
                  :items="tenantsList"
                  item-text="name"
                  item-value="id"
                  :label="$t('select_tenant_*')"
                  :rules="[rules.required]"
                />
                <!-- @change="onTenantChange" -->
              </v-col>
              <v-col
                v-for="(row, i) in config"
                :key="i"
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="configData[row.key]"
                  dense
                  :label="`${row.label} *`"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="toggleEdit()"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="isLoadingSave"
            @click="updateItem(item)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
      config: [],
      configData: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",
      //
      tenantBased: "tenantsConfigurations/getTenantBased",
    }),
  },
  async mounted() {
    this.onTenantChange(this.item.tenant_id);
  },
  methods: {
    async updateItem(item) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        let params = {
          tenant_id: item.tenant_id,
          configuration: { ...this.configData },
        };
        await this.$store
          .dispatch("tenantsConfigurations/add_update", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            // Callign list Again
            this.$store.dispatch(
              "tenantsConfigurations/list",
              this.filterOptions
            );
            this.isLoadingSave = false;
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
    async onTenantChange(val) {
      await this.$store.commit("tenantsConfigurations/SET_TENANT_BASED", {});
      this.config = [];

      if (val) {
        await this.$store
          .dispatch("tenantsConfigurations/tenantBased", {
            tenant_id: val,
          })
          .then((data) => {
            let params = {};
            data.configuration_fields.forEach((el) => {
              params.key = el.name;
              params.value = data.configuration[el.name] || 0;
              params.label = el.label;
              this.configData[el.name] = params.value;
            });
            this.config = [params];
          });
      }
    },
  },
};
</script>
